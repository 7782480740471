.multiSelectContainer .searchWrapper {
  border: 1px solid #e5e6eb;
  border-radius: 8px;
}

.multiSelectContainer input {
  margin-left: 15px;
  font-size: 16px;
  opacity: 0.6;
}

.multiSelectContainer li:hover {
  background: #ebebeb !important;
  color: #000 !important;
}

.multiSelectContainer .highlightOption {
  background: none !important;
  color: #000 !important;
}

.multiSelectContainer .icon_down_dir {
  right: 25px !important;
}

.multiSelectContainer .icon_down_dir::before {
  content: '' !important;
}

.multiSelectContainer .closeIcon {
  background: #8e8e8e !important;
}
