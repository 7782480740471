body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* overflow: hidden; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

textarea::placeholder {
  color: #9ea3ae;
}

/* .range-slider {
  height: 18px !important;
  background: url(./assets/slider_bg.svg) no-repeat !important;
} */

.blue-slider.range-slider {
  height: 18px !important;
  background: url(./assets/slider_bg.svg);
  background-size: cover;
}

/* .blue-slider .range-slider__range {
  background: url(./assets/slider_blue_fill.svg) no-repeat !important;
} */

.blue-slider.range-slider .range-slider__range {
  background: url(./assets/slider_blue_fill.svg);
  background-size: cover;
}

.blue-slider .range-slider__thumb[data-upper] {
  width: 35px !important;
  background: url(./assets/slider_thumb.svg) no-repeat !important;
}

.blue-slider .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

.blue-slider .range-slider__thumb[data-lower] {
  width: 0;
}

.blue-slider .range-slider__range {
  border-radius: 6px;
}

.see-more-text {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #594d6d;
  &:first-letter {
    text-transform: capitalize;
  }
}

.see-more-expand-text {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3772ff;
  text-transform: lowercase;
  cursor: default;
}

.react-pdf__Document {
  font-family: 'DM Sans';
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.react-pdf__Page {
  /* max-width: calc(100% - 2em); */
  margin: 0 0.5rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
}

.react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}
